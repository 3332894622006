.header {
  grid-row: 1/2;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 190px 3fr 60px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 0 30px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 80px;

  @media only screen and (max-width: 869px) {
    grid-template-columns: 150px 3fr 60px;
    width: 100%;
    height: 10vw;
  }

  @media only screen and (max-width: 801px) {
    grid-template-columns: 1fr 60px;
    width: 100%;
    height: 10vw;
  }

  @media only screen and (max-width: 500px) {
    padding: 0 20px;
  }

  &_sec1 {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    justify-self: start;

    img {
      width: 140px;
      @media only screen and (max-width: 950px) {
        width: 100px;
      }

      @media only screen and (max-width: 600px) {
        width: 65px;
      }

      // @media only screen and (max-width: 450px) {
      //   width: px;
      // }
    }
  }

  &_sec2 {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    gap: 10px;
    justify-self: end;
    .userProfile {
      @media only screen and (max-width: 801px) {
        display: none;
      }
    }
  }

  &_sec3 {
    display: none;
    @media only screen and (max-width: 801px) {
      display: block;
    }
    cursor: pointer;
    &:hover {
      box-shadow: 0 8px 25px -8px #82868b;
    }

    @media only screen and (max-width: 601px) {
      display: block;
    }
  }
}

.userProfile {
  @media only screen and (max-width: 601px) {
    display: none;
  }
}
