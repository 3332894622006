.headerAttendance {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
  grid-template-columns: 1fr;

  &__title {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  &__sec2 {
    grid-column: 2/3;
    grid-row: 1/2;
    display: grid;
    grid-auto-flow: column;
    justify-items: end;
    gap: 10px;
    width: 100%;

    &-btn {
      height: 30px;
      width: 100px;
      display: grid;
      align-self: center;
      vertical-align: middle;
      line-height: 5px;
    }
  }

  &__sec3 {
    grid-column: 1/3;
    grid-row: 2/3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.filter__sec {
  display: grid;
  grid-auto-flow: column;
  gap: 25px;
  grid-template-columns: 100px 1fr;
  padding: 5px;
  grid-row: 2/3;
  grid-column: 1/3;
}
.btn__sec1 {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 5fr 1fr;
  justify-items: center;
  background-color: #7367f0;
  border: none;
  color: white;
  border-radius: 4px;
  outline: none;
  width: 120px;
  height: min-content !important;
  padding: 2px 4px;

  &--1 {
    border: none !important;
    color: white;
    background-color: #7367f0;
    outline: none;
  }
}

.btn {
  &__main {
    display: flex;
    overflow: auto;
    gap: 10px;
    justify-items: start;

    &-user {
      display: grid;
      grid-auto-flow: column;
      gap: 10px;
      justify-items: start;
    }
  }
  &__sec2 {
    display: grid;
    grid-auto-flow: column;
    background-color: #82868b;
    border: none;
    color: white;
    border-radius: 4px;
    outline: none;
    gap: 5px;
    width: 100px;
    padding: 2px 0px;
    width: auto;
    justify-content: center;
    grid-template-columns: auto;
    padding: 2px 5px;

    &--1 {
      border: none !important;
      color: white;
      background-color: #82868b !important;
      outline: none;
    }
  }
}

.kWAsOx {
  width: 100% !important;
}

.ldeDAM {
  width: 100% !important;
}
